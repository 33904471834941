@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
/* Fonts */

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  font-family: Sans-Serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Sans-Serif !important;
}

.ant-btn-primary {
  background: #eca242 !important;
  border-color: #eca242 !important;
  border-radius: 4px;
}
.ant-btn-danger {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
  border-radius: 4px;
}
.ant-btn-cancel {
  border-radius: 4px;
}
.color-primary {
  color: #eca242 !important;
}
.img-logo {
  width: 100%;
  padding: 1rem;
}
.img-logo-collapsed {
  width: 80px;
  padding: 1rem;
}
.img-icon {
  width: 14px;
  height: auto;
}
.img-icon path {
  fill: #525252;
}
.img-icon-primary {
  width: 15.5px;
  height: auto;
}
.img-icon-primary path {
  fill: #eca242 !important;
}
.img-icon-primary-40 {
  width: 40px;
  height: auto;
}
.img-icon-primary-40 path {
  fill: #eca242 !important;
}
.icon-primary {
  color: #eca242 !important;
}
.border-primary {
  border-color: #e7e9ef;
  border-style: solid;
  border-width: thin;
}
.border-primary-right {
  border-right: thin solid #e7e9ef;
}
.marginBottom5px {
  margin-bottom: 5px;
}
.marginBottom15px {
  margin-bottom: 25px;
}
.marginTop15px {
  margin-top: 15px;
}
.marginTop10px {
  margin-top: 10px;
}
.marginLeft10px {
  margin-left: 10px;
}
.marginLeft20px {
  margin-left: 20px;
}
.marginRight10px {
  margin-right: 10px;
}
.marginRight20px {
  margin-right: 20px;
}
.fontSize12px {
  font-size: 12px;
}
.fontSize26px {
  font-size: 26px;
}
.fontWeight600 {
  font-weight: 600;
}
.fontWeight700 {
  font-weight: 700 !important;
}
.innerInput .ant-input:focus {
  box-shadow: unset !important;
}
.input-prefex-icon {
  width: 12px;
  height: 12px;
}
.inputTitle {
  font-size: 12px;
  margin-bottom: 8px;
}
.modalHeaderContainer {
  display: -webkit-flex;
  display: flex;
}
.modalIconContainer {
  margin-right: 10px;
}
.modalDialogContent {
  margin-left: 32px;
  font-size: 14px;
}
.modalSucessDialog {
  background-color: #15c668 !important;
  border-color: #15c668 !important;
  min-width: 50px !important;
  font-size: 14px !important;
  min-height: 3vh !important;
}
.modalErrorDialog {
  background-color: #e74141 !important;
  border-color: #e74141 !important;
  min-width: 50px !important;
  font-size: 14px !important;
  min-height: 3vh !important;
}
.displayFlex {
  display: -webkit-flex;
  display: flex;
}
.flexRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
}
.flexColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: -webkit-max-content;
  height: max-content;
}
.ant-menu-item-selected {
  color: #eca242 !important;
}
.ant-menu-item-selected path {
  fill: #eca242 !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #eca242 !important;
}
.ant-menu :hover {
  color: #eca242 !important;
}
.ant-menu :hover path {
  fill: #eca242 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #eca242 1f !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #eca242 !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #eca242 !important;
}
.ant-tabs-tab:hover {
  color: #e8a513 !important;
}
.ant-tabs-ink-bar {
  background: #eca242 !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-picker-range:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-input:hover {
  border-color: #eca242 !important;
}
.ant-input:focus {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:focus {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.merchant-new-form .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fdf6e8 !important;
}
.ant-modal-confirm-btns {
  text-align: end !important;
  margin-top: 20px !important;
}
.error-modal .ant-modal-content {
  border-radius: 20 !important;
}
.success-modal .ant-modal-content {
  border-radius: 0 !important;
}
.ant-form-vertical .ant-form-item-label > label {
  margin-bottom: 5px;
}
.ant-pagination-item {
  border-radius: 4px;
}
.ant-pagination-item:hover {
  border-color: #eca242;
}
.ant-pagination-item a:hover {
  color: #eca242;
}
.ant-pagination-item-active {
  border-radius: 4px;
  border-color: #eca242 !important;
}
.ant-pagination-item-active a {
  background-color: #eca242;
  color: white !important;
}
.ant-pagination-item-active a:hover {
  color: white !important;
}
.ant-pagination-item-active:hover {
  border-color: #eca242;
}
.ant-pagination-item-link {
  border-radius: 4px;
}
.ant-pagination-item-link:hover {
  color: #eca242 !important;
  border-color: #eca242 !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 4px;
}
.ant-tabs-ink-bar {
  background: #eca242 !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-picker-focused {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #eca242 !important;
}
.ant-picker-range:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #eca242 !important;
}
.ant-picker-decade-btn:hover,
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fdf6e8 !important;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  background: #eca242 !important;
  transition: unset !important;
}
.ant-picker-cell .ant-picker-cell-inner::after {
  background: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: #fdf6e8 !important;
}
.ant-select:hover {
  border-color: #eca242 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fdf6e8 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
    .ant-select-customize-input
  ).ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d5cfcf !important;
  height: 35px;
  border-radius: 4px;
  font-size: 12px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::before {
  border-color: #eca242 !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  border-color: #eca242 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
    .ant-select-customize-input
  ) .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-picker:hover {
  border-color: #eca242 !important;
}
.ant-input:hover {
  border-color: #eca242 !important;
}
.ant-input:focus {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:focus {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-form-vertical .ant-form-item-label > label {
  font-size: 12px;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-input:-ms-input-placeholder {
  font-size: 12px;
  border-radius: 4px;
}
.ant-input:placeholder-shown {
  font-size: 12px;
  border-radius: 4px;
}
.ant-input-affix-wrapper-disabled {
  height: 35px !important;
  border-radius: 4px;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-form-item {
  margin-bottom: 15px !important;
}
.ant-picker-input > input {
  font-size: 12px !important;
  height: 25px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-picker {
  border-radius: 4px !important;
}
.ant-input-affix-wrapper {
  height: 35px !important;
  border-radius: 4px;
}
.ant-select-item-option-content {
  font-size: 12px;
}
.ant-input {
  height: 35px !important;
  border-radius: 4px;
  font-size: 12px;
}
.ant-input-affix-wrapper > input.ant-input {
  height: unset !important;
}
.tableCss th.ant-table-cell-ellipsis {
  font-size: 14px !important;
  padding: 10px 10px !important;
}
.tableCss td.ant-table-cell-ellipsis {
  font-size: 14px !important;
  padding: 10px 10px !important;
}
.tableCss .ant-table-cell {
  padding: 10px 10px !important;
  font-size: 14px;
}
.tableCss .ant-select-selection-item {
  font-size: 14px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  height: 35px;
}
.table-list-head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.font-12px {
  font-size: 12px;
}
.font-16px {
  font-size: 16px;
}
.font-18px {
  font-size: 18px;
}
.font-20px {
  font-size: 20px;
}
.font-weight-600 {
  font-weight: 600;
}
.main_head-cotainer {
  color: #000000a6;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #ffffff;
}
.main_head-cotainer .row1 {
  font-size: 18px;
}
.main_head-cotainer .row2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;
  margin-right: 20px;
  font-size: 32px;
  font-weight: bold;
  color: #000000d9;
  min-width: 180px;
}
.main_head-cotainer .row3 {
  font-size: 12px;
  color: #000000a6;
}
.main_head-cotainer .icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.main_head-cotainer .icon .icon-svg {
  height: 40px !important;
  width: 40px !important;
  margin-left: 20px;
}
.main_head-cotainer .font-color-black {
  color: #000000;
}
.head-label {
  display: -webkit-flex;
  display: flex;
  color: #888686;
  font-size: 12px;
  margin-bottom: 5px;
}
.head-label span {
  margin-left: 4px;
}
.head-label .head-icon {
  width: 16px;
  height: auto;
  fill: #eca242 !important;
}
.head-label .head-icon-path {
  height: auto;
}
.head-label .head-icon-path path {
  fill: #eca242 !important;
}
.head-data {
  color: #000000a6;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 4px;
}
.head-detail-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.width-50p {
  width: 50%;
}
.width-75p {
  width: 75%;
}
.head-count-label {
  font-size: 16px;
  color: #919191;
  font-weight: 600;
  margin-right: 50px;
}
.head-count-label .head-count-data {
  color: #eca242 !important;
  font-size: 32px;
  font-weight: 600;
}
.breadcrumbs-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.breadcrumbs {
  display: -webkit-flex;
  display: flex;
}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
.display-none {
  display: none;
}
.readonly-field {
  border: none;
}
.readonly-field:focus {
  box-shadow: none !important;
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999999;
}
.loader .ant-spin {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
}
.max-width {
  width: 100%;
}
.width-300 {
  width: 300px;
}
.d-block {
  display: block;
}
.d-i-block {
  display: inline-block;
}
.d-flex {
  display: -webkit-flex;
  display: flex;
}
.max-height {
  height: 100%;
}
.height-18 {
  height: 18px;
}
.icon-left .ant-picker-suffix {
  position: absolute;
  margin: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.85);
}
.icon-left input {
  padding-left: 18px !important;
}
.ant-form-item-has-error .icon-left .ant-picker-suffix {
  color: #ff4d4f;
}
.custom-tooltip {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
}
.dynamic-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.dynamic-info h2 {
  margin: 0;
  font-weight: 600;
}
.dynamic-info p {
  text-align: center;
  margin: 20px 0;
}
.bg-primary {
  background-color: #1890ff;
}
.bg-secondary {
  background-color: #014050;
}
.bg-peach {
  background-color: #f7cb73;
}
.bg-green {
  background-color: #15c668;
}
.bg-white {
  background-color: #ffffff;
}
.bg-yellow {
  background-color: #eca242;
}
.bg-lightgray {
  background-color: #f6f8f9;
}
.box-shadow {
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.padding-s {
  padding: 10px;
}
.padding-s-v {
  padding: 10px 0;
}
.pad-bottom-s {
  padding-bottom: 10px;
}
.padding-m {
  padding: 20px;
}
.padding-l {
  padding: 30px;
}
.padding-m-v {
  padding: 20px 0;
}
.pad-bottom-s-s {
  padding-bottom: 8px;
}
.padding-m-inline {
  padding-inline: 24px;
}
.padding-horizontal-m {
  padding-left: 20px;
  padding-right: 20px;
}
.r-margin {
  margin: 0 !important;
}
.r-margin-l {
  margin-right: 30px;
}
.margin-top-vt {
  margin-top: 8px;
}
.margin-top-m {
  margin-top: 20px;
}
.margin-top-l {
  margin-top: 30px;
}
.margin-top-vtiny {
  margin-top: 8px;
}
.margin-bottom-m {
  margin-bottom: 20px;
}
.margin-m-v {
  margin: 20px 0;
}
.margin-top-vs {
  margin-top: 6px;
}
.marin-top-fare {
  margin-top: 10px;
}
.margin-right-st {
  margin-right: 2px;
}
.margin-right-vt {
  margin-right: 8px;
}
.margin-right-s {
  margin-right: 10px;
}
.margin-right-m {
  margin-right: 20px;
}
.margin-right-l {
  margin-right: 30px;
}
.margin-right-xll {
  margin-right: 80px;
}
.margin-left-st {
  margin-left: 2px;
}
.margin-left-vt {
  margin-left: 8px;
}
.margin-left-s {
  margin-left: 10px;
}
.margin-left-m {
  margin-left: 20px;
}
.margin-left-l {
  margin-left: 30px;
}
.margin-left-xll {
  margin-left: 80px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-26 {
  font-size: 26px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-45 {
  font-size: 45px;
}
.font-60 {
  font-size: 60px;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-400 {
  font-weight: 450;
}
.text-primary {
  color: #1890ff;
}
.text-secondary {
  color: #014050;
}
.text-white {
  color: #ffffff;
}
.text-gray {
  color: #525252;
}
.text-lightgray {
  color: gray;
}
.text-black {
  color: #000000;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.c-pointer {
  cursor: pointer;
}
.align-items-center {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-input-prefix {
  color: #113961;
}
.user-inut-icon {
  position: absolute;
  z-index: 5;
  top: 88px;
  left: 36px;
  color: #113961;
}
.pay-input .ant-select-selection-item {
  padding-left: 18px !important;
}
.sales-title {
  font-size: 26px;
  padding-left: 34px;
  font-weight: 600;
  color: #022955;
  padding-top: 20px;
  background-color: #ffff;
  margin-bottom: -2vh;
}
.tableClass th {
  color: #1890ff;
}
.tableClassOne thead > tr > th {
  color: 'pink';
}
.modalCss {
  background-color: yellow;
}
.position-relative {
  position: relative;
}

.ant-layout {
  height: 100vh;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-btn-primary {
  background: #eca242 !important;
  border-color: #eca242 !important;
  border-radius: 4px;
}
.ant-btn-danger {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
  border-radius: 4px;
}
.ant-btn-cancel {
  border-radius: 4px;
}
.color-primary {
  color: #eca242 !important;
}
.img-logo {
  width: 100%;
  padding: 1rem;
}
.img-logo-collapsed {
  width: 80px;
  padding: 1rem;
}
.img-icon {
  width: 14px;
  height: auto;
}
.img-icon path {
  fill: #525252;
}
.img-icon-primary {
  width: 15.5px;
  height: auto;
}
.img-icon-primary path {
  fill: #eca242 !important;
}
.img-icon-primary-40 {
  width: 40px;
  height: auto;
}
.img-icon-primary-40 path {
  fill: #eca242 !important;
}
.icon-primary {
  color: #eca242 !important;
}
.border-primary {
  border-color: #e7e9ef;
  border-style: solid;
  border-width: thin;
}
.border-primary-right {
  border-right: thin solid #e7e9ef;
}
.marginBottom5px {
  margin-bottom: 5px;
}
.marginBottom15px {
  margin-bottom: 25px;
}
.marginTop15px {
  margin-top: 15px;
}
.marginTop10px {
  margin-top: 10px;
}
.marginLeft10px {
  margin-left: 10px;
}
.marginLeft20px {
  margin-left: 20px;
}
.marginRight10px {
  margin-right: 10px;
}
.marginRight20px {
  margin-right: 20px;
}
.fontSize12px {
  font-size: 12px;
}
.fontSize26px {
  font-size: 26px;
}
.fontWeight600 {
  font-weight: 600;
}
.fontWeight700 {
  font-weight: 700 !important;
}
.innerInput .ant-input:focus {
  box-shadow: unset !important;
}
.input-prefex-icon {
  width: 12px;
  height: 12px;
}
.inputTitle {
  font-size: 12px;
  margin-bottom: 8px;
}
.modalHeaderContainer {
  display: -webkit-flex;
  display: flex;
}
.modalIconContainer {
  margin-right: 10px;
}
.modalDialogContent {
  margin-left: 32px;
  font-size: 14px;
}
.modalSucessDialog {
  background-color: #15c668 !important;
  border-color: #15c668 !important;
  min-width: 50px !important;
  font-size: 14px !important;
  min-height: 3vh !important;
}
.modalErrorDialog {
  background-color: #e74141 !important;
  border-color: #e74141 !important;
  min-width: 50px !important;
  font-size: 14px !important;
  min-height: 3vh !important;
}
.displayFlex {
  display: -webkit-flex;
  display: flex;
}
.flexRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
}
.flexColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: -webkit-max-content;
  height: max-content;
}
.ant-menu-item-selected {
  color: #eca242 !important;
}
.ant-menu-item-selected path {
  fill: #eca242 !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #eca242 !important;
}
.ant-menu :hover {
  color: #eca242 !important;
}
.ant-menu :hover path {
  fill: #eca242 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #eca242 1f !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #eca242 !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #eca242 !important;
}
.ant-tabs-tab:hover {
  color: #e8a513 !important;
}
.ant-tabs-ink-bar {
  background: #eca242 !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-picker-range:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-input:hover {
  border-color: #eca242 !important;
}
.ant-input:focus {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:focus {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.merchant-new-form .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fdf6e8 !important;
}
.ant-modal-confirm-btns {
  text-align: end !important;
  margin-top: 20px !important;
}
.error-modal .ant-modal-content {
  border-radius: 20 !important;
}
.success-modal .ant-modal-content {
  border-radius: 0 !important;
}
.ant-form-vertical .ant-form-item-label > label {
  margin-bottom: 5px;
}
.ant-pagination-item {
  border-radius: 4px;
}
.ant-pagination-item:hover {
  border-color: #eca242;
}
.ant-pagination-item a:hover {
  color: #eca242;
}
.ant-pagination-item-active {
  border-radius: 4px;
  border-color: #eca242 !important;
}
.ant-pagination-item-active a {
  background-color: #eca242;
  color: white !important;
}
.ant-pagination-item-active a:hover {
  color: white !important;
}
.ant-pagination-item-active:hover {
  border-color: #eca242;
}
.ant-pagination-item-link {
  border-radius: 4px;
}
.ant-pagination-item-link:hover {
  color: #eca242 !important;
  border-color: #eca242 !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 4px;
}
.ant-tabs-ink-bar {
  background: #eca242 !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-picker-focused {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #eca242 !important;
}
.ant-picker-range:hover {
  border-color: #eca242 !important;
  border-right-width: 1px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #eca242 !important;
}
.ant-picker-decade-btn:hover,
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fdf6e8 !important;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  background: #eca242 !important;
  transition: unset !important;
}
.ant-picker-cell .ant-picker-cell-inner::after {
  background: #eca242 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: #fdf6e8 !important;
}
.ant-select:hover {
  border-color: #eca242 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fdf6e8 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
    .ant-select-customize-input
  ).ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d5cfcf !important;
  height: 35px;
  border-radius: 4px;
  font-size: 12px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::before {
  border-color: #eca242 !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  border-color: #eca242 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
    .ant-select-customize-input
  ) .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-picker:hover {
  border-color: #eca242 !important;
}
.ant-input:hover {
  border-color: #eca242 !important;
}
.ant-input:focus {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:hover {
  border-color: #eca242 !important;
}
.ant-select-multiple .ant-select-selector:focus {
  border-color: #eca242 !important;
  box-shadow: 0 0 0 2px #f8edd7 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px #f8edd7 !important;
  border-color: #eca242 !important;
}
.ant-form-vertical .ant-form-item-label > label {
  font-size: 12px;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-input:-ms-input-placeholder {
  font-size: 12px;
  border-radius: 4px;
}
.ant-input:placeholder-shown {
  font-size: 12px;
  border-radius: 4px;
}
.ant-input-affix-wrapper-disabled {
  height: 35px !important;
  border-radius: 4px;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-form-item {
  margin-bottom: 15px !important;
}
.ant-picker-input > input {
  font-size: 12px !important;
  height: 25px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-picker {
  border-radius: 4px !important;
}
.ant-input-affix-wrapper {
  height: 35px !important;
  border-radius: 4px;
}
.ant-select-item-option-content {
  font-size: 12px;
}
.ant-input {
  height: 35px !important;
  border-radius: 4px;
  font-size: 12px;
}
.ant-input-affix-wrapper > input.ant-input {
  height: unset !important;
}
.tableCss th.ant-table-cell-ellipsis {
  font-size: 14px !important;
  padding: 10px 10px !important;
}
.tableCss td.ant-table-cell-ellipsis {
  font-size: 14px !important;
  padding: 10px 10px !important;
}
.tableCss .ant-table-cell {
  padding: 10px 10px !important;
  font-size: 14px;
}
.tableCss .ant-select-selection-item {
  font-size: 14px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  height: 35px;
}
.table-list-head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.font-12px {
  font-size: 12px;
}
.font-16px {
  font-size: 16px;
}
.font-18px {
  font-size: 18px;
}
.font-20px {
  font-size: 20px;
}
.font-weight-600 {
  font-weight: 600;
}
.main_head-cotainer {
  color: #000000a6;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #ffffff;
}
.main_head-cotainer .row1 {
  font-size: 18px;
}
.main_head-cotainer .row2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;
  margin-right: 20px;
  font-size: 32px;
  font-weight: bold;
  color: #000000d9;
  min-width: 180px;
}
.main_head-cotainer .row3 {
  font-size: 12px;
  color: #000000a6;
}
.main_head-cotainer .icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.main_head-cotainer .icon .icon-svg {
  height: 40px !important;
  width: 40px !important;
  margin-left: 20px;
}
.main_head-cotainer .font-color-black {
  color: #000000;
}
.head-label {
  display: -webkit-flex;
  display: flex;
  color: #888686;
  font-size: 12px;
  margin-bottom: 5px;
}
.head-label span {
  margin-left: 4px;
}
.head-label .head-icon {
  width: 16px;
  height: auto;
  fill: #eca242 !important;
}
.head-label .head-icon-path {
  height: auto;
}
.head-label .head-icon-path path {
  fill: #eca242 !important;
}
.head-data {
  color: #000000a6;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 4px;
}
.head-detail-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.width-50p {
  width: 50%;
}
.width-75p {
  width: 75%;
}
.head-count-label {
  font-size: 16px;
  color: #919191;
  font-weight: 600;
  margin-right: 50px;
}
.head-count-label .head-count-data {
  color: #eca242 !important;
  font-size: 32px;
  font-weight: 600;
}
.breadcrumbs-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.breadcrumbs {
  display: -webkit-flex;
  display: flex;
}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
.display-none {
  display: none;
}
.readonly-field {
  border: none;
}
.readonly-field:focus {
  box-shadow: none !important;
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999999;
}
.loader .ant-spin {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
}
.max-width {
  width: 100%;
}
.width-300 {
  width: 300px;
}
.d-block {
  display: block;
}
.d-i-block {
  display: inline-block;
}
.d-flex {
  display: -webkit-flex;
  display: flex;
}
.max-height {
  height: 100%;
}
.height-18 {
  height: 18px;
}
.icon-left .ant-picker-suffix {
  position: absolute;
  margin: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.85);
}
.icon-left input {
  padding-left: 18px !important;
}
.ant-form-item-has-error .icon-left .ant-picker-suffix {
  color: #ff4d4f;
}
.custom-tooltip {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
}
.dynamic-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.dynamic-info h2 {
  margin: 0;
  font-weight: 600;
}
.dynamic-info p {
  text-align: center;
  margin: 20px 0;
}
.bg-primary {
  background-color: #1890ff;
}
.bg-secondary {
  background-color: #014050;
}
.bg-peach {
  background-color: #f7cb73;
}
.bg-green {
  background-color: #15c668;
}
.bg-white {
  background-color: #ffffff;
}
.bg-yellow {
  background-color: #eca242;
}
.bg-lightgray {
  background-color: #f6f8f9;
}
.box-shadow {
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.padding-s {
  padding: 10px;
}
.padding-s-v {
  padding: 10px 0;
}
.pad-bottom-s {
  padding-bottom: 10px;
}
.padding-m {
  padding: 20px;
}
.padding-l {
  padding: 30px;
}
.padding-m-v {
  padding: 20px 0;
}
.pad-bottom-s-s {
  padding-bottom: 8px;
}
.padding-m-inline {
  padding-inline: 24px;
}
.padding-horizontal-m {
  padding-left: 20px;
  padding-right: 20px;
}
.r-margin {
  margin: 0 !important;
}
.r-margin-l {
  margin-right: 30px;
}
.margin-top-vt {
  margin-top: 8px;
}
.margin-top-m {
  margin-top: 20px;
}
.margin-top-l {
  margin-top: 30px;
}
.margin-top-vtiny {
  margin-top: 8px;
}
.margin-bottom-m {
  margin-bottom: 20px;
}
.margin-m-v {
  margin: 20px 0;
}
.margin-top-vs {
  margin-top: 6px;
}
.marin-top-fare {
  margin-top: 10px;
}
.margin-right-st {
  margin-right: 2px;
}
.margin-right-vt {
  margin-right: 8px;
}
.margin-right-s {
  margin-right: 10px;
}
.margin-right-m {
  margin-right: 20px;
}
.margin-right-l {
  margin-right: 30px;
}
.margin-right-xll {
  margin-right: 80px;
}
.margin-left-st {
  margin-left: 2px;
}
.margin-left-vt {
  margin-left: 8px;
}
.margin-left-s {
  margin-left: 10px;
}
.margin-left-m {
  margin-left: 20px;
}
.margin-left-l {
  margin-left: 30px;
}
.margin-left-xll {
  margin-left: 80px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-26 {
  font-size: 26px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-45 {
  font-size: 45px;
}
.font-60 {
  font-size: 60px;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-400 {
  font-weight: 450;
}
.text-primary {
  color: #1890ff;
}
.text-secondary {
  color: #014050;
}
.text-white {
  color: #ffffff;
}
.text-gray {
  color: #525252;
}
.text-lightgray {
  color: gray;
}
.text-black {
  color: #000000;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.c-pointer {
  cursor: pointer;
}
.align-items-center {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.ant-input-prefix {
  color: #113961;
}
.user-inut-icon {
  position: absolute;
  z-index: 5;
  top: 88px;
  left: 36px;
  color: #113961;
}
.pay-input .ant-select-selection-item {
  padding-left: 18px !important;
}
.sales-title {
  font-size: 26px;
  padding-left: 34px;
  font-weight: 600;
  color: #022955;
  padding-top: 20px;
  background-color: #ffff;
  margin-bottom: -2vh;
}
.tableClass th {
  color: #1890ff;
}
.tableClassOne thead > tr > th {
  color: 'pink';
}
.modalCss {
  background-color: yellow;
}
.position-relative {
  position: relative;
}
.sidebar-root {
  height: 100%;
  -webkit-flex-wrap: inherit;
          flex-wrap: inherit;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-color: #e4e4e5;
  border-style: solid;
  border-width: thin;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
}
.sidebar-root .profile-info {
  width: 80%;
  padding: 0.5rem 0;
  overflow: hidden;
  margin: auto auto 0;
  border-top: 1px solid #e7e9ef;
  transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-root .profile-info .ant-list-item-meta {
  -webkit-align-items: center;
          align-items: center;
}
.sidebar-root .profile-info .ant-list-item-meta-avatar {
  margin-right: 12px;
}
.sidebar-root .profile-info .ant-list-item-meta-content {
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-root .profile-info .ant-list-item-meta-title {
  margin: 0;
  font-size: 18px;
  color: #014050;
  width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-root .profile-info .ant-list-item-meta-description {
  color: #a8a8a8;
}
.sidebar-root .profile-info .ant-list-item-meta-description > span {
  position: relative;
}
.sidebar-root .profile-info .ant-list-item-meta-description > span .anticon {
  position: absolute;
  top: 4px;
  right: -18px;
}
.ant-layout-sider-collapsed .sidebar-root .ant-list-item-meta-content {
  width: 0;
  opacity: 0;
}
.tooltip-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.tooltip-icon {
  width: 14px;
  height: auto;
}
.tooltip-icon path {
  fill: #ffffff !important;
}

.header-rightIcon .ant-scroll-number {
  z-index: 1;
}
.icon-container .notification-icon > svg > g > circle {
  display: none;
}
.icon-container .notification-icon > svg > g > g > path {
  stroke: #1890ff !important;
}
@media (min-width: 768px) {
  .ant-dropdown-menu-settings {
    left: calc(100% - 150px) !important;
  }
}
.header-submenu {
  position: relative;
  width: 130px;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.header-submenu:before {
  content: "\A";
  position: absolute;
  right: 0;
  top: -10px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ffffff transparent transparent;
}
.header-submenu .ant-dropdown-menu-item {
  padding: 0.5rem;
}
.header-submenu .ant-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.modal-notifications {
  position: absolute;
  right: 72px;
  top: 75px !important;
}
.modal-notifications .ant-modal-content {
  position: relative;
}
.modal-notifications .ant-modal-content:before {
  content: "\A";
  position: absolute;
  right: 0;
  top: -10px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ffffff transparent transparent;
}
.modal-notifications .ant-modal-content .ant-modal-close {
  display: none;
}
.modal-notifications .ant-modal-content .ant-modal-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.modal-notifications .ant-modal-content .ant-modal-title > a {
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
}
.modal-notifications .ant-modal-content .ant-modal-body {
  height: 500px !important;
}

